var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newNote-wrap"},[_c('NavBar'),_c('div',{staticClass:"box"},[_c('div',{staticClass:"line",on:{"click":function($event){_vm.$router.push('marketingSMS');
        _vm.$store.commit('updateMemberScreening', {
          member_card_state: -1,
          member_card_type: '',
          member_consume_num: [],
          member_consume_time: '',
          member_out_day: 0,
          member_pay_money: [],
          member_pay_money_time: '',
          member_sex: 2
        });}}},[_c('span',[_vm._v("选择全部会员")]),_c('van-icon',{attrs:{"name":"arrow"}})],1),_c('div',{staticClass:"line",on:{"click":function($event){return _vm.$router.push('memberScreening')}}},[_c('span',[_vm._v("自定义会员")]),_c('van-icon',{attrs:{"name":"arrow"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }