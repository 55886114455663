<!--
 功能名称:千里眼平台(短信)-新建短信
 开发人:梁高权
 创建时间:2021/05/20
 最后修改时间:2021/05/20
-->
<template>
  <div class="newNote-wrap">
    <NavBar />
    <div class="box">
      <div
        class="line"
        @click="
          $router.push('marketingSMS');
          $store.commit('updateMemberScreening', {
            member_card_state: -1,
            member_card_type: '',
            member_consume_num: [],
            member_consume_time: '',
            member_out_day: 0,
            member_pay_money: [],
            member_pay_money_time: '',
            member_sex: 2
          });
        "
      >
        <span>选择全部会员</span>
        <van-icon name="arrow" />
      </div>
      <div class="line" @click="$router.push('memberScreening')">
        <span>自定义会员</span>
        <van-icon name="arrow" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar';
export default {
  name: 'newNote',
  components: { NavBar }
};
</script>

<style lang="scss" scoped>
.newNote-wrap {
  .box {
    padding: 30px;
    .line {
      border-radius: 16px;
      background: #fff;
      font-size: 28px;
      color: #333;
      font-weight: bold;
      height: 100px;
      margin-bottom: 19px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
